import { get, map, mapValues } from 'lodash'
// eslint-disable-next-line import/no-cycle
import { getReq } from '../../utils/request'
import { LOAD_FINISHES_PER_MONTH } from './types'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { IResetStore } from '../generalTypes'

export type IStatisticsActions = IResetStore
	| IGetFinishesPerMonthAction

// tasks
export interface IGetFinishesPerMonthAction {
	type: LOAD_FINISHES_PER_MONTH
	payload: IFinishesPerMonthPayload
}

export interface IFinishesPerMonthPayload {
	finishesPerMonth: FinishesPerMonthOriginTableItem[]
}

export type FinishesPerMonthOriginTableItem = {
	count: number
	date: string
}

export const getFinishesPerMonthStats = (params: any = {}): ThunkResult<Promise<IFinishesPerMonthPayload>> => async (dispatch) => {
	let payload = {} as IFinishesPerMonthPayload
	try {
		dispatch({ type: LOAD_FINISHES_PER_MONTH.START })

		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/statistics/finishes-per-month', normalizeQueryParams)

		const originalData: FinishesPerMonthOriginTableItem[] = get(data, 'data', [])
		const tableData: FinishesPerMonthOriginTableItem[] = map(originalData, (column) => ({
			count: column.count,
			date: column.date
		}))

		payload = {
			finishesPerMonth: tableData
		}

		dispatch({
			type: LOAD_FINISHES_PER_MONTH.DONE,
			payload
		})
		return data
	} catch (error) {
		dispatch({ type: LOAD_FINISHES_PER_MONTH.FAILED })
		Promise.reject(error)
		return error
	}
}

export interface IOrganizationInfoPayload {
	data: IOrganizationInfoRecord[]
}

export interface IOrganizationInfoRecord {
	organizationId: number;
	name: number;
	workerCount: string;
	finishCount: string;
	date: string;
	organizationName: string;
}

export const getOrganizationInfoStats = (
	onSuccess: (data: IOrganizationInfoPayload) => void = (() => {})
): ThunkResult<Promise<IOrganizationInfoPayload>> => async (dispatch) => {
	try {
		const queries = {
			limit: 20,
			page: 1
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/statistics/all-organizations-with-stats', normalizeQueryParams)

		const originalData: IOrganizationInfoPayload[] = get(data, 'data', [])

		onSuccess(data)
		return originalData
	} catch (error) {
		dispatch({ type: LOAD_FINISHES_PER_MONTH.FAILED })
		Promise.reject(error)
		return error
	}
}
